const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
const poll = (
    condition,
    timeout = 2000,
    interval = 100,
    data = null,
) => {
    const endTime = Number(new Date()) + timeout;

    const poller = (
        resolve,
        reject,
    ) => {
        if (condition()) {
            resolve(data);
        } else if (Number(new Date()) < endTime) {
            setTimeout(poller, interval, resolve, reject);
        } else {
            reject(new Error('Polling timed out'));
        }
    };

    return new Promise(poller);
};

const sortCoupons = (a, b) => {
    const typePriority = {
        "fixed_amount": 1,
        "percentage": 2
    };

    // Define a priority order for coupon durations
    const durationPriority = {
        "forever": 1,
        "one_time": 2,
        "limited_period": 3
    };
    // Compare coupon types and durations
    const typeComparison = typePriority[a.type] - typePriority[b.type];
    const durationComparison = durationPriority[a.duration] - durationPriority[b.duration];

    // If types are different, prioritize by type
    if (typeComparison !== 0) {
        return typeComparison;
    }

    // If durations are different, prioritize by duration
    if (durationComparison !== 0) {
        return durationComparison;
    }

    // If type and duration are the same, sort by createdAt (newest to oldest)
    return b.createdAt - a.createdAt;
}

const getBestTier = (tiers, quantity) => {
    if (quantity === 0) {
        return false;
    }

    for (let i = 0; i < tiers.length; i++) {
        const tier = tiers[i];

        if (quantity <= tier.maxUnits) {
            if (i === tiers.length - 1 || quantity < tiers[i + 1].maxUnits) {
                return tier;
            }
            if (!tiers[i + 1].maxUnits) {
                return tier;
            }
        }
    }

    return false;
}

const getTierKeyByMaxUnits = (tiers, quantity) => {
    for (let i = 0; i < tiers.length; i++) {
        const tier = tiers[i];

        if (!!tier.maxUnits && quantity < tier.maxUnits) {
            return i;
        }
        if (!tier.maxUnits) {
            return i;
        }
    }

    return tiers.length - 1;
}

const pluralize = (word, number) => {
    if (number != 1) {
        return word + 's';
    } else {
        return word;
    }
}

const priceFormat = (number) => {
    number = Math.round(number * 1000) / 1000;
    const roundedNumber = Math.round(number * 100) / 100;

    if (Number.isInteger(roundedNumber)) {
        return parseInt(roundedNumber, 10).toLocaleString();
    } else {
        return parseFloat(roundedNumber).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2});
    }
}
const _toTitleCase = (string) => {
    // Replace underscores with spaces
    let stringWithSpaces = string.replace(/_/g, ' ');

    // Split the string into an array of words
    let words = stringWithSpaces.split(' ');

    // Capitalize the first letter of each word
    let titleCaseWords = words.map(word => {
        if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
        return word; // Handle case where there are multiple underscores in a row
        }
    });

    // Join the title case words back into a single string
    let titleCaseString = titleCaseWords.join(' ');

    return titleCaseString;
}

export {
    getCookie,
    poll,
    getBestTier,
    getTierKeyByMaxUnits,
    pluralize,
    priceFormat,
    _toTitleCase,
    sortCoupons,
}
