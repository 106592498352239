/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

// We need to predefine some methods for sentry so we don't get an error if a logging event fires before sentry is loaded.
window.Sentry = {
    loaded: false,
    logError(error, extra = null) {
        console.log('Sentry not loaded, error not logged.');
        // console.error(error, extra);
    },
    logInfo(error, extra = null) {
        console.log('Sentry not loaded, info not logged.');
        // console.error(error, extra);
    },
    setUser(email) {
        console.log('Sentry not loaded, user not set');
    }
}

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
