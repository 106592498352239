const pushToDataLayer = (data) => {
    try {
        dataLayer.push(data);
    } catch (e) {
        // Do nothing. GTM is not loaded
    }
};

export {
    pushToDataLayer,
};
